/**
 * Dependencies
 */
@import "~normalize.css";
@import "@angular/cdk/overlay-prebuilt.css";
@import "node_modules/swiper/swiper.scss";
@import "node_modules/swiper/modules/navigation/navigation.scss";
@import "node_modules/swiper/modules/pagination/pagination.scss";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

/**
 * License
 */
@import "fonts";
@import "variables";

/**
 * Core
 */
@import "mixins";
@import "functions";
@import "variables-scss";
@import "variables-css";

/** UI */
@import "embeds";
@import "forms";
@import "modals";
@import "icons";
@import "texts";
@import "lists";
@import "controls/button";
@import "controls/filter";
@import "controls/input";
@import "controls/select";
@import "layouts/contextmenu";
@import "layouts/grid";
@import "layouts/list-header";
@import "layouts/main-header";
@import "layouts/section-header";
@import "layouts/tabs";

/**
 * Global
 */

* {
  box-sizing: border-box;
  line-height: calc(2ex + 0.4em);
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 4px var(--color-outline);
    ._forceDark & {
      box-shadow: 0 0 0 4px var(--color-outline-dark);
    }
  }
}

img {
  display: block;
  max-width: 100%;
}

a {
  color: inherit;
}

html {
  font-size: 16px;
  &.cdk-global-scrollblock {
    height: 100vh;
    height: -webkit-fill-available;
  }
}

body {
  font-family: $font-primary;
  font-variation-settings: "wght" 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-foreground);
  background-color: var(--color-background);
  font-size: _clamp($font-size);
  letter-spacing: 0.015em;
  overflow-x: hidden;
  .cdk-global-scrollblock & {
    height: 100vh;
    height: -webkit-fill-available;
    overflow-x: unset;
  }
  &:has(app-menu) {
    overflow: hidden;
  }
}

._scrollIntoView {
  --mainHeaderMargin: calc(
    #{_clamp($height-headerMain)} + #{_clamp($spacing-medium)} - 1px
  );
  outline: solid 4px tint("light-3", 25%);
  outline-offset: _clamp($spacing-tiny);
  &._scrollIntoView--outlineHidden {
    transition: outline-color 2s $easing-in-out-cubic;
    outline-color: transparent;
  }
  &._scrollIntoView--topHeaderMargin {
    scroll-margin-top: calc(
      #{_clamp((3.25rem, 5.5rem))} + #{_clamp($spacing-medium)} - 1px
    );
  }
  &._scrollIntoView--mainHeaderMargin {
    scroll-margin-top: var(--mainHeaderMargin);
  }
  &._scrollIntoView--mainHeaderSidemenuMargin {
    scroll-margin-top: var(--mainHeaderMargin);
    @media screen and (max-width: $width-content-medium) {
      scroll-margin-top: calc(
        #{_clamp($height-headerMain)} + #{_clamp((2.625rem, 3rem))} + #{_clamp(
            $spacing-medium
          )} - 1px
      );
    }
  }
}

/** Angular CDK Overlay */

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  height: 100vh;
  height: -webkit-fill-available;
}

.cdk-overlay-dark-backdrop {
  background-color: rgba(black, 0.66);
}

/** Quill Editor */

quill-editor {
  inline-size: 100%;

  .ql-editor,
  .ql-tooltip {
    font-family: $font-secondary;
    font-size: _clamp($font-size);
    a {
      color: inherit;
    }
    b,
    strong {
      font-variation-settings: "wght" 500;
    }
  }

  .ql-editor {
    min-height: inherit;
    overflow: visible; // Prevents cutting of link tooltip
    box-shadow: inset 0 0 0 1px var(--color-foreground);
    padding: _clamp($spacing-small);
    p,
    ol,
    ul,
    pre,
    blockquote,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:not(:first-child) {
        margin-top: 1em;
      }
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
    &:focus {
      box-shadow: inset 0 0 0 1px var(--color-foreground),
        0 0 0 4px var(--color-light-3);
    }
    ._form:not(._form--validateOnSubmit) .ng-dirty.ng-invalid &,
    ._form--validateOnSubmit._form--submitted .ng-invalid & {
      color: var(--color-danger);
      box-shadow: inset 0 0 0 1px var(--color-danger),
        0 0 0 4px alpha("danger", 25%);
      &:focus {
        box-shadow: inset 0 0 0 1px var(--color-danger),
          0 0 0 4px alpha("danger", 50%);
      }
    }
    &.ql-blank::before {
      font-style: normal;
    }
  }

  .ql-tooltip {
    z-index: 9999;
  }

  .ql-snow {
    &.ql-toolbar {
      border-color: var(--color-foreground);
      border-block-end: none;
      .ql-formats {
        display: inline-flex;
        gap: _clamp($spacing-xtiny);
      }
      .ql-active {
        background-color: var(--color-light-3);
      }
      .ql-active,
      button:hover {
        .ql-stroke {
          stroke: var(--color-foreground);
        }
      }
    }
    &.ql-container {
      border: none;
    }
    .ql-tooltip {
      box-shadow: none;
      padding: _clamp($spacing-tiny) _clamp($spacing-small);
      border-color: var(--color-foreground);
      &::before {
        content: "";
        margin-right: 0;
      }
      a.ql-action::after {
        content: "Bewerk";
        margin: 0 _clamp($spacing-tiny);
      }
      a.ql-remove::before {
        content: "Verwijder";
      }
      a.ql-action::after {
        content: "Bewerk";
      }
      &.ql-editing a.ql-action::after {
        content: "Opslaan";
      }
      &[data-mode="link"]::before {
        content: "";
      }
      &[data-mode="formula"]::before {
        content: "Formule:";
      }
      &[data-mode="video"]::before {
        content: "Video:";
      }
      input[type="text"] {
        font-size: 16px;
        width: 260px;
        height: 32px;
        padding: 0 6px;
        border: none;
        box-shadow: none;
      }
    }
  }
  &:has(.ql-disabled) {
    .ql-toolbar,
    .ql-editor {
      opacity: 0.5;
    }
    .ql-toolbar {
      pointer-events: none;
    }
  }
}

/** Swiper */

.swiper {
  --swiper-navigation-size: 22px;
}

.swiper:not(.fullscreen-swiper) {
  padding-bottom: calc(var(--swiper-navigation-size) + #{_clamp($spacing)});
  @media screen and (max-width: $width-content-small) {
    padding-bottom: calc(
      var(--swiper-navigation-size) + #{_clamp($spacing-large)}
    );
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: var(--color-foreground);
    &.swiper-button-disabled {
      pointer-events: all;
    }
  }

  .swiper-button-prev {
    bottom: 0;
    left: calc(100% - (13.5px * 2) - #{_clamp($spacing)});
    top: auto;
  }

  .swiper-button-next {
    bottom: 0;
    left: calc(100% - 13.5px);
    top: auto;
  }

  .swiper-pagination {
    text-align: left;
    left: 0 !important;
    width: calc(100% - (13.5px * 2) - #{_clamp($spacing)}) !important;
    bottom: 0;
    padding-left: 0;
    @media screen and (max-width: $width-content-xlarge) {
      padding-left: 0;
    }
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  &.swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    --sizeBullet: 9px;
    width: var(--sizeBullet);
    height: var(--sizeBullet);
    border-radius: 0;
    background-color: var(--color-foreground);
    ._forceDark & {
      opacity: 0.5;
    }
  }

  .swiper-pagination-bullet-active {
    &,
    ._forceDark & {
      opacity: 1;
    }
  }

  &.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  &.swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 calc(var(--sizeBullet) / 2);
  }
}

.fullscreen-swiper {
  &.swiper {
    padding-bottom: 0;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @include button-reset();
    @include button();
    background-color: transparent;
    border: none;
    text-shadow: 0 0 20px black;
  }

  .swiper-button-prev {
    left: calc(#{_clamp($width-gap-outer)} + 5px);
  }

  .swiper-button-next {
    left: auto;
    right: calc(#{_clamp($width-gap-outer)} + 5px);
  }
}
