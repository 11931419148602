/** 
 * Converts colors into HEX and exploded RGB CSS variables.
 */
@mixin convertHexColorsIntoCSSVariables($colors) {
  @each $name, $color in $colors {
    --color-#{$name}: #{$color};
    --color-#{$name}-r: #{red($color)};
    --color-#{$name}-g: #{green($color)};
    --color-#{$name}-b: #{blue($color)};
  }
}

/**
 * Keeps an element with a mandatory inner container at a specified aspect ratio.
 * Parameters must be unitless values.
 */
@mixin aspect-ratio($width, $height) {
  position: relative;
  display: block;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc((#{$height} / #{$width}) * 100%);
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/**
 * Injects base styles for a button
 */
@mixin button() {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 _clamp($spacing);
  height: _clamp($height-control);
  background-color: var(--color-foreground);
  border: 1px solid var(--color-foreground);
  color: var(--color-background);
  text-align: center;
  overflow: hidden;
  text-transform: lowercase;
  font-family: $font-secondary;
  line-height: normal;
  &:disabled {
    background-color: alpha('foreground', 50%);
    color: alpha('background', 75%);
    border-color: transparent;
    cursor: default;
  }
  &:not(:disabled):active {
    opacity: .5;
  }
}

/**
 * Injects base styles for a title
 */
@mixin title() {
  color: var(--color-foreground);
  font-weight: $theme-title-font-weight;
  font-size: _clamp($font-size);
  letter-spacing: normal;
  word-break: break-word; // Safari < 15.4
  overflow-wrap: anywhere;
  &,
  & * {
    line-height: calc(2ex + .25em);
  }
}

/**
 * Cuts off a single line with an ellipsis if the text overflows.
 */
@mixin text-ellipsis() {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
 * Limits a text block to a specified number of lines and adds an ellipsis if the text overflows.
 */
@mixin text-clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

/**
 * Breaks out a background color so it runs from window edge to window edge horizontally.
 * The element should be positioned relatively, absolutely or sticky.
 */
@mixin breakout-background-color($direction: 'both', $width: 100%) {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 50vw;
    background-color: inherit;
    border: inherit;
    @if ($direction == 'both') {
      width: 100vw;
      margin-left: calc(-50vw + 50%);
      left: 0;
    }
    @else if ($direction == 'left') {
      right: 0;
    }
    @else if ($direction == 'right') {
      left: 0;
    }
  }  
}

/**
 * Resets a list
 */
@mixin list-reset() {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * Resets a button
 */
@mixin button-reset() {
  appearance: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  color: inherit;
  display: inline-block;
  text-decoration: none;
  font-variation-settings: 'wght' 0;
}

/**
 * Resets a text input or text area
 */
@mixin text-input-reset() {
  appearance: none;
  outline: none;
  font-size: 1em;
  display: block;
  font-weight: normal;
  font-family: $font-secondary;
  font-variation-settings: "wght" 1;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

/** Returns a chevron */
@mixin chevron(
  $direction: "right",
  $size: 20px,
  $strokeWidth: 1px,
  $color: var(--color-foreground)) {

  display: block;
  width: $size;
  height: $size;
  border-top: 1px solid $color;
  border-right: 1px solid $color;
  border-width: $strokeWidth;
  transform-origin: calc(#{$size} / 2);
    
  @if $direction == 'top'    { 
    transform: rotate(-45deg);
  }
  @if $direction == 'right'  { 
    transform: rotate(45deg);
  }
  @if $direction == 'bottom' { 
    transform: rotate(135deg);
  }
  @if $direction == 'left'   { 
    transform: rotate(225deg);
  }
}

/** Styles the scrollbar */
@mixin scrollbar() {
  overflow: auto;
  overflow: overlay;
  @media (hover: hover) {
    &::-webkit-scrollbar {
      height: 6px;
    }   
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: transparent;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: alpha('foreground', 10%);
      ._forceDark & {
        background: alpha('foreground', 40%);
      }
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 6px;
      padding: 0;
    }
  }  
}