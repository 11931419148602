@import 'mixins';
@import 'functions';

._button {
  @include button-reset();
  @include button();
}

._button--danger {
  @extend ._button;
  background-color: var(--color-danger);
  border-color: var(--color-danger);
  color: var(--color-foreground-on-danger);
  &:focus-visible {
    box-shadow: 0 0 0 4px tint('danger', 50%);
  }
}

._button--notice {
  @extend ._button;
  background-color: var(--color-highlight);
  color: var(--color-foreground);
  border-color: var(--color-highlight);
  &:focus-visible {
    box-shadow: 0 0 0 4px tint('accent-2', 50%);
  }
}

._button--inverse {
  @extend ._button;
  background-color: var(--color-background);
  border-color: var(--color-background);
  color: var(--color-foreground);
  &:disabled {
    background-color: alpha('background', 25%);
    border-color: transparent;
  }
}

._button--medium {
  @extend ._button;
  padding: 0 _clamp($spacing);
  height: _clamp($height-controlMedium);
}

._button--small {
  @extend ._button;
  padding: 0 _clamp($spacing-small);
  height: _clamp($height-controlSmall);
}

._button--tiny {
  @extend ._button;
  padding: 0 _clamp($spacing-tiny);
  font-size: _clamp($font-size-small);
  height: _clamp($height-controlTiny);
}

._button--inline {
  @extend ._button;
  display: inline-flex;
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  height: auto;
  &:disabled {
    background: none;
    color: inherit;
    opacity: .5;
  }
}

._button--outline {
  @extend ._button;
  color: var(--color-foreground);
  background-color: transparent;
  border: 1px solid var(--color-foreground);
  &:disabled {
    background-color: transparent;
    color: alpha('foreground', 50%);
    border-color: alpha('foreground', 50%);
    opacity: 1;
  }
}

._button--outlineHover {
  @extend ._button--outline;
  @media (hover: hover) {
    &:not(:disabled):hover {
      background-color: var(--color-foreground);
      color: var(--color-background);
    }
  }
}

._button--outlineHoverDanger {
  @extend ._button--outlineHover;
  @media (hover: hover) {
    &:not(:disabled):hover {
      background-color: var(--color-danger);
      border-color: var(--color-danger);
      color: var(--color-foreground-on-danger);
    }
  }
}

._button--circle {
  @extend ._button;
  width: auto;
  aspect-ratio: 1 / 1;
  border-radius: _clamp($height-control);
  padding: 0;
  line-height: 100%;
}

._button--transparent {
  @extend ._button;
  color: var(--color-foreground);
  background: none;
  border: none;
  &:disabled {
    color: var(--color-foreground);
    background: none;
    opacity: .5;
  }
}

._button-content {
  display: flex;
  align-items: center;
}

._button-icon {
  line-height: normal;
  align-self: center;
  text-decoration: none;
  &:first-child:not(:only-child) {
    margin-right: _clamp($spacing-tiny);
  }
  &:last-child:not(:only-child) {
    margin-left: _clamp($spacing-tiny);
  }
  @media screen and (max-width: $width-content-small) {
    ._button--iconOnlyWhenNarrow & {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

._button-label {
  @include text-ellipsis();
  line-height: normal;
  flex: 1;
  ._button--inline & {
    text-decoration: underline;
  }
  @media screen and (max-width: $width-content-small) {
    ._button--iconOnlyWhenNarrow & {
      display: none;
    }
  }
}

._button--toggle {
  @extend ._button;
  text-decoration: none;
  ._button-label {
    text-decoration: underline;
  }
  > input {
    display: none;
  }
}

._button--toggleInline {
  @extend ._button--inline;
  @extend ._button--toggle;
}

._button--toggleOutline {
  @extend ._button--outline;
  @extend ._button--toggle;
}

._button--play {
  @extend ._button--circle;
  ._button-icon {
    margin-left: 0;
    margin-right: 0 !important;
    &._button--play-iconPlay {
      margin-left: .175em;
    }
    &._button--play-iconPause {
      display: none;
      margin-left: 0;
    }
  }
  &._button--playCurrent {
    background-color: var(--color-fluo);
    color: #1d1d1b;
    border: none;
  }
  &._button--playPlaying {
    @media (hover: hover) {
      &:hover {
        ._button-icon {
          &._button--play-iconPlay {
            display: none;
          }
          &._button--play-iconPause {
            display: block;
          }
        }
      }
    }
    @media (hover: none) {
      ._button-icon {
        &._button--play-iconPlay {
          display: none;
        }
        &._button--play-iconPause {
          display: block;
        }
      }
    }
  }
}

._button--playOutline {
  @extend ._button--play;
  @extend ._button--outline;
  color: var(--color-fluo);
  border-color: var(--color-fluo);
}

._button--fluo {
  @extend ._button;
  font-family: $font-primary;
  background-color: var(--color-fluo);
  color: var(--color-foreground);
  border: none;
  height: auto;
  &:disabled {
    background-color: var(--color-midground);
    color: var(--color-foreground);
  }
  @media (hover: hover) {
    &:not(:disabled):hover {
      background-color: var(--color-foreground);
      color: var(--color-background);
    }
  }
}