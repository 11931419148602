@import 'variables-scss';
@import 'functions';
@import 'mixins';

._grid {
  @include list-reset();
  display: grid;
  gap: _clamp($width-gap-outer);
  row-gap: calc(#{_clamp($width-gap-outer)} * 2);
  align-items: flex-start;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  @media screen and (max-width: 634px) {
    grid-template-columns: 1fr;
  }
}

._grid--large {
  @extend ._grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

._grid--small {
  @extend ._grid;
  row-gap: _clamp($width-gap-outer);
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  @media screen and (max-width: $width-content-small) {
    grid-template-columns: repeat(auto-fill, minmax(86px, 1fr));
  }
}

// Substract 2 from order to set position. So for position 5, choose 3.
._grid-ad {
  grid-column: span 1;
  order: 3;
  &:nth-of-type(2) {
    order: 8;
  }
  @media screen and (max-width: $width-content-small) {
    grid-column: span 1;
  }
}