@import 'variables-scss';
@import 'mixins';
@import 'functions';

._list-header,
._list-header-group {
  @include list-reset();
  display: flex;
  min-width: 0;
  flex: 1;
  @media screen and (max-width: $width-content-small) {
    display: none;
  }
}

._list-header-item {
  min-width: 0;
  flex: 1;
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-left: _clamp($spacing-small);
  }
}

._list-header-item--main {
  @extend ._list-header-item;
  flex: 2;
}

._list-header-item--meta {
  @extend ._list-header-item;
  flex: 4;
}

._list-header-item-button {
  @include button-reset();
  display: inline-flex;
  &:disabled {
    opacity: .5;
    cursor: default;
  }
}

._list-header-item--alignEnd {
  @extend ._list-header-item;
  justify-content: flex-end;
}

._list-header-item-label {
  @include text-ellipsis();
  font-family: $font-secondary;
  font-size: _clamp($font-size-small);
  text-transform: uppercase;
  font-variation-settings: 'wght' 500; 
}

._list-header-item-sortIcon {
  display: none;
  font-size: _clamp($font-size-small);
  margin-left: _clamp($spacing-xtiny);
  ._list-header-item--sorted & {
    display: inline-block;
  }
}