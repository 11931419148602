@import 'variables-scss';
@import 'mixins';
@import 'functions';

._section-header {
  width: 100%;
  min-height: _clamp($height-controlSmall);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: _clamp($spacing);
}

._section-header--includeLayout {
  @extend ._section-header;
}

._section-header--wrapWhenNarrow {
  @extend ._section-header;
  @media screen and (max-width: $width-content-small) {
    display: block;
    min-height: 0;
  }
}

._section-header-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

._section-header-title {
  @include text-ellipsis();
  color: var(--color-foreground);
  font-weight: bold;
  font-size: _clamp($font-size);
  letter-spacing: normal;
  word-break: break-word; // Safari < 15.4
  overflow-wrap: anywhere;
  &,
  & * {
    line-height: calc(2ex + .25em);
  }
  text-transform: lowercase;
  flex: 1;
  margin: 0;
  display: flex;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  ._section-header-content & {
    width: 100%;
    flex: 0 0 auto;
  }
  &:before {
    content: '';
    order: 3;
    flex: 1;
    height: 1px;
    background-color: var(--color-foreground);
    margin-left: _clamp($spacing);
    margin-top: calc((2ex + .25em) / 2);
  }
  ._forceDark &:before {
    background-color: alpha('foreground', 50%);
  }
  @media screen and (max-width: $width-content-small) {
    ._section-header--wrapWhenNarrow & {
      width: 100%;
    }    
  }
}

._section-header-title--required {
  @extend ._section-header-title;
  &:after {
    content: ' *';
    order: 2;
  }  
}

._section-header-subtitle {
  @include text-ellipsis();
  font-family: $font-secondary;
  opacity: .5;
  margin: 0;
  ._section-header-content & {
    width: 100%;
    flex: 0 0 auto;
  }
}

._section-header-actions {
  flex: 0 0 auto;
  margin-left: _clamp($spacing);
  display: flex;
  gap: _clamp($spacing-small);
  @media screen and (max-width: $width-content-small) {
    ._section-header--wrapWhenNarrow & {
      margin-left: 0;
      width: 100%;
      margin-top: _clamp($spacing-small);
    }
  }
}

._section-header-action {
  flex: 0 0 auto;
  @at-root a#{&} {
    margin: 0 calc(#{_clamp($spacing-small, true)} / 2);
    &:last-child {
      margin-right: _clamp($spacing-small, true);
    }
  }
  @at-root button#{&} {
    margin: 0 calc(#{_clamp($spacing-small, true)} / 2);
    &:last-child {
      margin-right: _clamp($spacing-small, true);
    }
  }
  @media screen and (max-width: $width-content-small) {
    ._section-header--wrapWhenNarrow & {
      margin-left: 0;
      &:not(:last-child) {
        margin-right: _clamp($spacing);
      }
    }
  }
}