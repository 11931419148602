/**
 * SASS Variables
 * ==============
 * Used for styles that are fixed on runtime.
 */

/**
 * Import the theme variables
 */

@import 'variables';
 
/**
 * Assign the theme variables
 */

$colors: $theme-colors;
$colorsDarkMode: $theme-colorsDarkMode;
$font-primary: $theme-font-primary;
$font-secondary: $theme-font-secondary;
$size-border-radius: $theme-size-border-radius;
$logo-main: $theme-logo-main;
$logo-main-menu: $theme-logo-main-menu;
$logo-main-width: $theme-logo-main-width;
$logo-main-height: $theme-logo-main-height;
$header-logo-size: $theme-header-logo-size;
$footer-logo-size: $theme-footer-logo-size;

/**
 * Assign the fixed variables
 */

$font-size-massive: (2.5rem, 5rem); // 40px, 80px
$font-size-huge: (2rem, 2.625rem); // 32px, 42px
$font-size-bigger: (1.75rem, 2rem); // 24px, 28px
$font-size-big: (1.5rem, 1.75rem); // 24px, 28px
$font-size-large: (1.3125rem, 1.5rem); // 21px, 24px
$font-size-medium: (1.125rem, 1.3125rem); // 18px, 21px
$font-size: (1rem, 1.125rem); // 16px, 18px
$font-size-small: (0.8125rem, 0.9375rem); // 13px, 15px
$font-size-tiny: (0.6875rem, 0.75rem); // 11px, 12px

$width-gap-outer-min: 1.171875rem;
$width-gap-outer-max: 2.5rem;
$width-gap-outer: ($width-gap-outer-min, $width-gap-outer-max);
$width-clamp: (360px, 1332px); // Widths in which the linear viewport clamp happens
$width-content-max: 1332px;
$width-content-max-inclPadding: calc(#{$width-content-max} + (#{nth($width-gap-outer, 2)} * 2));
$width-content-xlarge: 1200px;
$width-content-large: 960px;
$width-content-medium: 768px;
$width-content-small: 520px;
$width-content-tiny: 420px;
$width-aside: (15rem, 22.5rem);
$width-avatar-min: 8.25rem;
$width-avatar-max: 12rem;
$width-avatar: ($width-avatar-min, $width-avatar-max);
$width-avatar-narrow-min: 5.5rem;
$width-avatar-narrow-max: 8rem;
$width-avatar-narrow: ($width-avatar-narrow-min, $width-avatar-narrow-max);
$width-avatar-small-min: 2.75rem;
$width-avatar-small-max: 4rem;
$width-avatar-small: ($width-avatar-small-min, $width-avatar-small-max);
$width-avatar-tiny-min: 1.75rem;
$width-avatar-tiny-max: 2.25rem;
$width-avatar-tiny: ($width-avatar-tiny-min, $width-avatar-tiny-max);

$height-headerTop-min: 3.3375rem;
$height-headerTop-max: 5.25rem;
$height-headerTop: ($height-headerTop-min, $height-headerTop-max);
$height-headerMain-min: 5rem;
$height-headerMain-max: 8rem;
$height-headerMain: ($height-headerMain-min, $height-headerMain-max);
$height-player-min: 3rem;
$height-player-max: 4rem;
$height-player: ($height-player-min, $height-player-max);
$height-player-small: 3rem;
$height-control-min: 2.25rem;
$height-control-max: 3.125rem;
$height-control: ($height-control-min, $height-control-max);
$height-controlMedium-min: 2.625rem;
$height-controlMedium-max: 4rem;
$height-controlMedium: ($height-controlMedium-min, $height-controlMedium-max);
$height-controlSmall-min: 1.875rem;
$height-controlSmall-max: 2.5rem;
$height-controlSmall: ($height-controlSmall-min, $height-controlSmall-max);
$height-controlTiny-min: 1.25rem;
$height-controlTiny-max: 1.5625rem;
$height-controlTiny: ($height-controlTiny-min, $height-controlTiny-max);

$spacing-xtiny-min: .15rem;
$spacing-xtiny-max: .25rem;
$spacing-xtiny: ($spacing-xtiny-min, $spacing-xtiny-max);
$spacing-tiny-min: .33rem;
$spacing-tiny-max: .5rem;
$spacing-tiny: ($spacing-tiny-min, $spacing-tiny-max);
$spacing-small-min: .5rem;
$spacing-small-max: .75rem;
$spacing-small: ($spacing-small-min, $spacing-small-max);
$spacing-min: .75rem;
$spacing-max: 1.125rem;
$spacing: ($spacing-min, $spacing-max);
$spacing-medium-min: 1.125rem;
$spacing-medium-max: 1.875rem;
$spacing-medium: ($spacing-medium-min, $spacing-medium-max);
$spacing-large-min: 2rem;
$spacing-large-max: 3rem;
$spacing-large: ($spacing-large-min, $spacing-large-max);
$spacing-xlarge-min: 2.75rem;
$spacing-xlarge-max: 5rem;
$spacing-xlarge: ($spacing-xlarge-min, $spacing-xlarge-max);

$easing-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);