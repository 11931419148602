@import 'variables-scss';
@import 'functions';

._tabs {
  border: 1px solid var(--color-foreground);
  display: flex;
}

._tabs-item {
  @include button-reset();
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: _clamp($height-controlSmall);
  padding: 0 _clamp($spacing-small);
  text-decoration: none;
  text-transform: uppercase;
  font-size: _clamp($font-size-tiny);
  font-family: $font-secondary;
  letter-spacing: .15em;
  &:not(:last-child) {
    border-right: 1px solid var(--color-foreground);
  }
  &:active {
    background-color: var(--color-shade-light);
  }
  &._tabs-item--active {
    background-color: var(--color-foreground);
    color: var(--color-background);
  }
  &:focus-visible {
    box-shadow: inset 0 0 0 4px var(--color-outline);
  }
}

._tabs-item-label {
  flex: 0 0 auto;
}

._tabs-item-badge {
  background-color: var(--color-midground);
  color: var(--color-foreground);
  padding: 0 .33em;
  margin-left: _clamp($spacing-small);
  font-size: _clamp($font-size-tiny);
  ._tabs-item--active & {
    background-color: var(--color-background);
  }
}