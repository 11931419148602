@import "mixins";
@import "functions";
@import "variables-scss";

._input--text {
  @include text-input-reset();
  box-shadow: inset 0 0 0 1px var(--color-foreground);
  color: var(--color-foreground);
  line-height: normal;
  background-color: inherit;
  padding: _clamp($spacing-small);
  height: _clamp($height-control);
  border-radius: 0;
  @at-root input[type="date"]#{&},
    input[type="datetime-local"]#{&} {
    background-color: var(--color-background);
  }
  @at-root input[type="date"]#{&},
    input[type="datetime-local"]#{&},
    input[type="number"]#{&} {
    max-width: $width-content-tiny;
  }
  @at-root textarea#{&} {
    width: 100%;
    height: auto;
    min-height: _clamp($height-control);
    min-width: 100%;
    max-width: 100%;
    resize: vertical;
  }
  &::placeholder {
    text-transform: lowercase;
  }
  &[type="datetime-local"]::placeholder {
    text-transform: none; // Makes sure to keep capital T: jjjj-mm-ddThh:mm
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  &:focus {
    box-shadow: inset 0 0 0 1px var(--color-foreground),
      0 0 0 4px var(--color-outline);
  }
  ._form:not(._form--validateOnSubmit) &.ng-dirty.ng-invalid,
  ._form--validateOnSubmit._form--submitted &.ng-invalid,
  &._input--validateInstantly.ng-invalid {
    color: var(--color-danger);
    box-shadow: inset 0 0 0 1px var(--color-danger),
      0 0 0 4px alpha("danger", 25%);
    &:focus {
      box-shadow: inset 0 0 0 1px var(--color-danger),
        0 0 0 4px alpha("danger", 50%);
    }
  }
}

._input--textSmall {
  @extend ._input--text;
  height: _clamp($height-controlSmall);
  min-height: _clamp($height-controlSmall);
  @at-root textarea#{&} {
    height: auto;
  }
}

._input--radio,
._input--checkbox {
  position: relative;
  display: block;
  cursor: pointer;
}

._input--checkbox-input,
._input--radio-input {
  display: none;
}

._input--radio-label,
._input--checkbox-label {
  display: flex;
  gap: _clamp($spacing-small);
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: $font-secondary;
  &::before {
    pointer-events: none;
    display: flex;
    content: " ";
    height: _clamp($spacing);
    width: _clamp($spacing);
    border: solid 1px var(--color-foreground);
    margin-top: 0.05em;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    font-size: _clamp((0.75rem, 1rem));
    overflow: hidden;
    flex: 0 0 auto;
  }
  input:disabled + & {
    opacity: 0.5;
    cursor: default;
  }
  ._input--checkboxOverlay &,
  ._input--radioOverlay & {
    &::before {
      position: absolute;
      margin: 0;
      background-color: var(--color-background);
      top: _clamp($spacing-tiny);
      left: _clamp($spacing-tiny);
      z-index: 1;
    }
  }
  ._form:not(._form--validateOnSubmit) input.ng-dirty.ng-invalid + &,
  ._form--validateOnSubmit._form--submitted input.ng-invalid + &,
  input._input--validateInstantly.ng-invalid + & {
    &::before {
      border-color: var(--color-danger);
      box-shadow: 0 0 0 4px alpha("danger", 25%);
      &:focus {
        box-shadow: 0 0 0 4px alpha("danger", 50%);
      }
    }
  }
}

._input--radio-label::before {
  border-radius: 50%;
}

._input--radio ._input--radio-input:checked + ._input--radio-label::before,
._input--radio
  ._input--radio-input:active:not(:disabled)
  + ._input--radio-label::before {
  background-color: var(--color-foreground);
  box-shadow: inset 0 0 0 2px var(--color-background);
}

._input--radio
  ._input--radio-input:active:not(:disabled)
  + ._input--radio-label::before {
  background-color: alpha("background", 33.33%);
}

._input--checkbox
  ._input--checkbox-input:checked
  + ._input--checkbox-label::before,
._input--checkbox
  ._input--checkbox-input:active:not(:disabled)
  + ._input--checkbox-label::before {
  content: "\f00c";
}

._input--checkbox
  ._input--checkbox-input:active:not(:disabled)
  + ._input--checkbox-label::before {
  color: alpha("foreground", 33.33%);
}
