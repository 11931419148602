@import 'variables-scss';
@import 'mixins';
@import 'functions';

._title {
  @include title();
}

._title--massive {
  @extend ._title;
  font-size: _clamp($font-size-massive);
}

._title--huge {
  @extend ._title;
  font-size: _clamp($font-size-huge);
}

._title--bigger {
  @extend ._title;
  font-size: _clamp($font-size-bigger);
}

._title--big {
  @extend ._title;
  font-size: _clamp($font-size-big);
}

._title--large {
  @extend ._title;
  font-size: _clamp($font-size-large);
}

._title--medium {
  @extend ._title;
  font-size: _clamp($font-size-medium);
}

._title--small {
  @extend ._title;
  font-size: _clamp($font-size-small);
}

._title--tiny {
  @extend ._title;
  font-size: _clamp($font-size-small);
}

._title--secondary {
  @extend ._title;
  font-weight: bold;
}

._uppercase {
  @include title();
  text-transform: $theme-title-text-transform;
}

._uppercase--massive {
  @extend ._uppercase;
  font-size: _clamp($font-size-massive);
}

._uppercase--huge {
  @extend ._uppercase;
  font-size: _clamp($font-size-huge);
}

._uppercase--bigger {
  @extend ._uppercase;
  font-size: _clamp($font-size-bigger);
}

._uppercase--big {
  @extend ._uppercase;
  font-size: _clamp($font-size-big);
}

._uppercase--large {
  @extend ._uppercase;
  font-size: _clamp($font-size-large);
}

._uppercase--medium {
  @extend ._uppercase;
  font-size: _clamp($font-size-medium);
}

._uppercase--small {
  @extend ._uppercase;
  font-size: _clamp($font-size-small);
}

._text {
  color: var(--color-foreground);
  font-size: _clamp($font-size);
  font-weight: normal;
  letter-spacing: normal;
  word-break: break-word; // Safari < 15.4
  overflow-wrap: anywhere; 
  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
}

._text--massive {
  @extend ._text;
  font-size: _clamp($font-size-massive);
}

._text--huge {
  @extend ._text;
  font-size: _clamp($font-size-huge);
}

._text--bigger {
  @extend ._text;
  font-size: _clamp($font-size-bigger);
}

._text--big {
  @extend ._text;
  font-size: _clamp($font-size-big);
}

._text--large {
  @extend ._text;
  font-size: _clamp($font-size-large);
}

._text--medium {
  @extend ._text;
  font-size: _clamp($font-size-medium);
}

._text--small {
  @extend ._text;
  font-size: _clamp($font-size-small);
}

._text--tiny {
  @extend ._text;
  font-size: _clamp($font-size-small);
}

._caption {
  display: block;
  color: var(--color-foreground);
  font-family: $font-secondary;
  font-weight: normal;
  font-variation-settings: 'wght' 150;
  text-transform: uppercase;
  font-size: _clamp($font-size-tiny);
  letter-spacing: .15em;
}

._caption--danger {
  @extend ._caption;
  color: var(--color-danger);
}

._caption--highlight {
  @extend ._caption;
  color: var(--color-highlight);
}

._caption--warning {
  @extend ._caption;
  color: var(--color-warning);
}

._caption--tiny {
  @extend ._caption;
  font-size: _clamp($font-size-tiny)
}

._label {
  --color: var(--color-foreground);
  text-transform: uppercase;
  font-family: $font-secondary;
  font-size: _clamp($font-size-small);
  letter-spacing: .1em;
  color: var(--color);
  position: relative;
  padding: 0 2px;
  border-radius: 0px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color);
    opacity: .075;
    z-index: -1;
  }
}

._label--tiny {
  @extend ._label;
  font-size: _clamp($font-size-tiny);
}

._label--transparent {
  @extend ._label;
  &:after {
    content: none;
  }
}

._label--accent {
  @extend ._label;
  --color: var(--color-highlight);
}

._label--success {
  @extend ._label;
  --color: var(--color-success);
}

._label--warning {
  @extend ._label;
  --color: var(--color-warning);
}

._label--danger {
  @extend ._label;
  --color: var(--color-danger);
}

._hasAnchors * {
  scroll-margin-top: var(--heightAppHeader);  
}