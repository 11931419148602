@import "mixins";
@import "functions";

._select {
  @include button-reset();
  font-family: $font-secondary;
  position: relative;
  display: flex;
  max-width: $width-content-tiny;
  text-align: left;
  &:not(._select--inline):after {
    position: absolute;
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: "\f078";
    right: _clamp($spacing);
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: var(--color-foreground);
  }
  &._select--disabled {
    opacity: 0.5;
    cursor: default;
    &:after {
      opacity: 0.5;
    }
  }
}

._select--inline,
._select--shadowed,
._select--small {
  @extend ._select;
}

._select--solid {
  @extend ._select;
  &:after {
    color: var(--color-background);
  }
}

._select-control {
  @include text-ellipsis();
  @include button-reset();
  width: 100%;
  background: none;
  border-radius: 0;
  border: 1px solid var(--color-foreground);
  height: _clamp($height-control);
  padding: 0 _clamp($spacing-large) 0 _clamp($spacing-small);
  display: flex;
  align-items: center;
  &:disabled,
  ._select--disabled & {
    cursor: default;
    opacity: 1;
  }
  ._select:not(._select--disabled) &:disabled {
    opacity: 0.5;
  }
  ._select--inline & {
    height: auto;
    border: none;
    padding: 0;
    display: inline-flex;
  }
  ._select--small & {
    padding: 0 _clamp($spacing-small);
    padding-right: _clamp($spacing-large);
    height: _clamp($height-controlSmall);
  }
  ._select--solid & {
    background-color: var(--color-foreground);
    color: var(--color-background);
    border: none;
  }
  ._form:not(._form--validateOnSubmit) &.ng-dirty.ng-invalid,
  ._form--validateOnSubmit._form--submitted &.ng-invalid {
    color: var(--color-danger);
    border-color: var(--color-danger);
    box-shadow: 0 0 0 4px alpha("danger", 25%);
    &:focus-visible {
      box-shadow: 0 0 0 4px alpha("danger", 50%);
    }
  }
  ._select--shadowed &,
  ._form:not(._form--validateOnSubmit) ._select--shadowed &.ng-dirty.ng-invalid,
  ._form--validateOnSubmit._form--submitted ._select--shadowed &.ng-invalid {
    color: transparent;
    user-select: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

._select-shadow {
  @extend ._select-control;
  display: none;
  ._select--shadowed & {
    display: flex;
    pointer-events: none;
    color: var(--color-foreground);
    position: relative;
    justify-content: center;
    text-align: center;
    text-decoration: underline;
  }
  @media (hover: hover) {
    ._select--shadowed & {
      text-decoration: none;
    }
    ._select--shadowed:hover & {
      text-decoration: underline;
    }
  }
  ._select--solid._select--shadowed & {
    color: var(--color-background);
  }
  ._form:not(._form--validateOnSubmit) ._select-control.ng-dirty.ng-invalid + &,
  ._form--validateOnSubmit._form--submitted ._select-control.ng-invalid + & {
    color: var(--color-danger);
    border-color: var(--color-danger);
  }
}
