@import "variables-scss";
@import "mixins";
@import "functions";

._modal {
  width: 100vw;
  max-width: $width-content-small;
  border-radius: $size-border-radius;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: _clamp($width-gap-outer);
}

._modal--notice,
._modal--error {
  @extend ._modal;
}

._modal--large {
  @extend ._modal;
  height: 75vh;
}

._modal-header {
  flex: 0 0 auto;
  background-color: var(--color-background);
  width: 100%;
  max-width: $width-content-small;
  display: flex;
  align-items: center;
  ._modal:not(._modal--error, ._modal--notice) & {
    @include convertHexColorsIntoCSSVariables($colorsDarkMode);
  }
  ._modal--error & {
    background-color: var(--color-danger);
  }
  ._modal--notice & {
    background-color: var(--color-highlight);
  }
}

._modal-title {
  @include title();
  font-size: _clamp($font-size-large);
  padding: _clamp($spacing);
  flex: 1;
  margin: 0;
  .modal--error & {
    color: var(--color-foreground-on-danger);
  }
}

._modal-header-action {
  @include button-reset();
  @include button();
  flex: 0 0 auto;
  color: var(--color-foreground);
  background: none;
  border: none;
  &:disabled {
    color: var(--color-foreground);
    background: none;
    opacity: 0.5;
  }
}

._modal-content {
  padding: _clamp($spacing);
  margin: 0;
  flex: 1;
  width: 100%;
  max-height: 840px;
  overflow: auto;
  background-color: var(--color-background);
}

._modal-content--noPadding {
  @extend ._modal-content;
  padding: 0;
}

._modal-notice {
  inline-size: 100%;
  flex: 0 0 auto;
  padding: _clamp($spacing);
  background-color: var(--color-light-1);
  border-top: 1px solid var(--color-foreground);
}

._modal-actions {
  flex: 0 0 auto;
  padding: _clamp($spacing);
  border-top: 1px solid var(--color-foreground);
  width: 100%;
  background-color: var(--color-background);
  display: flex;
  > *:not(:last-child) {
    flex: 0 0 auto;
    margin-right: _clamp($spacing-small);
    width: auto;
  }
}
