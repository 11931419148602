@import "functions";
@import "mixins";

._form {
  position: static;
}

._form--validateOnSubmit {
  @extend ._form;
}

._form-row {
  &:not(:last-child) {
    margin-bottom: _clamp($spacing-medium);
  }
}

._form-row--smallMargin {
  @extend ._form-row;
  &:not(:last-child) {
    margin-bottom: _clamp($spacing);
  }
}

._form-row-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: _clamp($spacing-small);
}

._form-row-header--noSpaceBetween {
  @extend ._form-row-header;
  justify-content: flex-start;
}

._form-row-header--extraMargin {
  @extend ._form-row-header;
  margin-bottom: _clamp($spacing);
}

._form-row-header-append {
  display: flex;
  flex: 0 0 auto;
  margin-left: _clamp($spacing-small);
}

._form-row-header-append-item {
  &:not(:last-child) {
    margin-right: _clamp($spacing-small);
  }
}

._form-label {
  display: block;
  &:not(:has(+ ._form-sublabel)) {
    margin-bottom: _clamp($spacing-small);
  }
  ._form-row-header & {
    margin-bottom: 0;
  }
  ._form-row-header:not(._form-row-header--noSpaceBetween) & {
    flex: 1;
  }
}

._form-label--required {
  @extend ._form-label;
  &:after {
    content: " *";
  }
}

._form-inline {
  display: flex;
  &:not(:last-child) {
    margin-bottom: _clamp($spacing-small);
  }
}

._form-inlineWrap {
  display: inline-flex;
  flex-wrap: wrap;
  margin: calc(#{_clamp($spacing, true)} / 2);
  & > * {
    margin: calc(#{_clamp($spacing)} / 2);
  }
}

._form-inline-item {
  flex: 1;
  ._form-inlineWrap & {
    flex: 0 0 auto;
  }
  &:not(:last-child) {
    margin-right: _clamp($spacing-small);
  }
}

._form-inline-item--fixed {
  @extend ._form-inline-item;
  flex: 0 0 auto;
}

._form-group {
  display: flex;
  align-items: flex-start;
  > *:not(._form-group-prepend):not(._form-group-append) {
    flex: 1;
  }
}

._form-group--center {
  @extend ._form-group;
  align-items: center;
}

._form-group-pend {
  display: flex;
  flex: 0 0 auto;
  min-height: _clamp($height-control);
}

._form-group-prepend {
  @extend ._form-group-pend;
  margin-right: 0;
  > *:not(._form-group-text) {
    margin-right: _clamp($spacing-small);
  }
}

._form-group-append {
  @extend ._form-group-pend;
  margin-left: 0;
  > *:not(._form-group-text) {
    margin-left: _clamp($spacing-small);
  }
}

._form-group-text {
  width: 100%;
  font-family: $font-secondary;
  color: alpha("foreground", 50%);
  padding: _clamp($spacing-small);
  height: _clamp($height-control);
  ._form-group-prepend & {
    box-shadow: inset 0 1px 0 var(--color-foreground),
      inset 1px 0 0 var(--color-foreground),
      inset 0 -1px 0 var(--color-foreground);
  }
  ._form-group-append & {
    box-shadow: inset 0 1px 0 var(--color-foreground),
      inset -1px 0 0 var(--color-foreground),
      inset 0 -1px 0 var(--color-foreground);
  }
}

._form-info {
  @include list-reset();
  color: var(--color-foreground);
  font-family: $font-secondary;
  margin-top: _clamp($spacing-small);
  &:empty {
    display: none;
  }
  @at-root p#{&} {
    opacity: 0.5;
  }
  &._form-info--error {
    color: var(--color-danger);
    opacity: 1;
  }
  li {
    opacity: 0.5;
    &:not(:last-child) {
      margin-bottom: _clamp($spacing-xtiny);
    }
    &._form-info--error {
      color: var(--color-danger);
      opacity: 1;
    }
  }
}

._form-sublabel {
  @extend ._form-info;
  margin-bottom: _clamp($spacing-small);
  margin-top: 0;
}

._form-error {
  font-family: $font-secondary;
  color: var(--color-danger);
  margin-top: _clamp($spacing-small);
  > *:not(:last-child) {
    margin-bottom: _clamp($spacing-small);
  }
}

._form-maxLength {
  display: flex;
  align-items: center;
  color: var(--color-foreground);
  font-family: $font-secondary;
  font-weight: 400;
  text-transform: uppercase;
  font-size: _clamp($font-size-small);
  letter-spacing: 0.1em;
  opacity: 0.5;
  &._form-maxLength--error {
    opacity: 1;
    color: var(--color-danger);
  }
}
