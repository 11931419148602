/** Basis Grotesque */

@font-face {
  font-family: 'Basis Grotesque';
  src: url('../assets/basis-grotesque-regular.eot');
  src: url('../assets/basis-grotesque-regular.eot?#iefix') format('embedded-opentype'),
       url('../assets/basis-grotesque-regular.woff2') format('woff2'),
       url('../assets/basis-grotesque-regular.woff') format('woff'),
       url('../assets/basis-grotesque-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basis Grotesque';
  src: url('../assets/basis-grotesque-medium.eot');
  src: url('../assets/basis-grotesque-medium.eot?#iefix') format('embedded-opentype'),
       url('../assets/basis-grotesque-medium.woff2') format('woff2'),
       url('../assets/basis-grotesque-medium.woff') format('woff'),
       url('../assets/basis-grotesque-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basis Grotesque';
  src: url('../assets/basis-grotesque-bold.eot');
  src: url('../assets/basis-grotesque-bold.eot?#iefix') format('embedded-opentype'),
       url('../assets/basis-grotesque-bold.woff2') format('woff2'),
       url('../assets/basis-grotesque-bold.woff') format('woff'),
       url('../assets/basis-grotesque-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/** Phantom Sans */

@font-face {
  font-family: 'Phantom Sans';
  src: url('../assets/PhantomSans0.6VAR.ttf') format('truetype');
  font-display: swap;
}