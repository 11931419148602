@use 'sass:math';
@import 'variables-scss';

@function tint($colorName, $pct) {
  @return rgb(
    calc(var(--color-#{$colorName}-r) + (#{math.div($pct, 100%)} * (255 - var(--color-#{$colorName}-r))))
    calc(var(--color-#{$colorName}-g) + (#{math.div($pct, 100%)} * (255 - var(--color-#{$colorName}-g))))
    calc(var(--color-#{$colorName}-b) + (#{math.div($pct, 100%)} * (255 - var(--color-#{$colorName}-b))))
  );
}

@function shade($colorName, $pct) {
  @return rgb(
    calc(var(--color-#{$colorName}-r) * #{math.div((100% - $pct), 100%)})
    calc(var(--color-#{$colorName}-g) * #{math.div((100% - $pct), 100%)})
    calc(var(--color-#{$colorName}-b) * #{math.div((100% - $pct), 100%)})
  );
}


@function alpha($colorName, $pct) {
  @return rgba(
    var(--color-#{$colorName}-r),
    var(--color-#{$colorName}-g),
    var(--color-#{$colorName}-b),
    #{math.div($pct, 100%)}
  );
}

/**
 * Linearly scales a value between a minimum and maximum based on a minimum and maximum viewport width.
 * ====================================================================================================
 * $size: (rem, rem)
 * Based on https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
 */
@function _clamp($size, $negative: false) {

  // Remove the units
  $minSize: math.div(nth($size, 1), 1rem);
  $maxSize: math.div(nth($size, 2), 1rem);
  $minWidth: math.div(nth($width-clamp, 1), 1px);
  $maxWidth: math.div(nth($width-clamp, 2), 1px);
  
  // Assuming 1rem is 16px
  $pixelsPerRem: 16;

  // Calculate the values
  $minWidth: math.div($minWidth, $pixelsPerRem);
  $maxWidth: math.div($maxWidth, $pixelsPerRem);
  $slope: (math.div(($maxSize - $minSize), ($maxWidth - $minWidth)));
  $yAxisIntersection: -$minWidth * $slope + $minSize;

  // Return the native CSS clamp function
  @if ($negative == true) {
    @return clamp(#{-$maxSize}rem, calc((#{$yAxisIntersection}rem + #{$slope * 100}vw) * -1), #{-$minSize}rem);
  } 
  @else {
    @return clamp(#{$minSize}rem, calc(#{$yAxisIntersection}rem + #{$slope * 100}vw), #{$maxSize}rem);
  }
}