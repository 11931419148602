@import 'variables-scss';
@import 'mixins';
@import 'functions';

$optionMinWidth: (5.3125rem, 6.5625rem);

._filter {
  @include scrollbar();
  display: flex;
  border: 1px solid var(--color-foreground);
  &:not(:last-child) {
    border-bottom: none;
  }
}

._filter-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

._filter-search-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: _clamp($spacing-large);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: _clamp($font-size-small);
  pointer-events: none;
}

._filter-search-input {
  @include text-input-reset();
  flex: 1;
  height: _clamp($height-control);
  padding: 0 _clamp($spacing) 0 _clamp($spacing-large);
  &:focus-within {
    box-shadow: inset 0 0 0 4px var(--color-outline);
  }
}

._filter-toggleAdvanced {
  flex: 0 0 auto;
  padding: 0 _clamp($spacing);
  height: _clamp($height-control);
  font-size: _clamp($font-size-tiny);
  text-transform: uppercase;
  letter-spacing: .1em;
  &:focus-visible {
    box-shadow: inset 0 0 0 4px var(--color-outline);    
  }
}

._filter-toggleAdvanced-count {
  $size: _clamp((1.25rem, 1.75rem));
  flex: 0 0 auto;
  width: $size;
  height: $size;
  background-color: var(--color-foreground);
  color: var(--color-background);
  font-size: _clamp($font-size-small);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

._filter-reset {
  @include button-reset();
  flex: 0 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: _clamp($height-control);
  min-width: _clamp($optionMinWidth);
  padding: 0 _clamp($spacing);
  color: var(--color-foreground);
  background-color: var(--color-background);
  box-shadow: 1px 0 0 var(--color-foreground);
  font-size: _clamp($font-size-tiny);
  text-transform: uppercase;
  &:focus-within {
    box-shadow: inset 0 0 0 4px var(--color-outline), 1px 0 0 var(--color-foreground), -1px 0 0 var(--color-foreground);
  }
  &:active {
    color: alpha('foreground', 50%);
  }
  &._filter-reset--active {
    background-color: var(--color-foreground);
    color: var(--color-background);
  }
}

._filter-select {
  width: 100%;
  max-width: 420px;
  box-shadow: 1px 0 0 var(--color-foreground);
}

._filter-checkbox {
  flex: 0 0 auto;
}

._filter-checkbox-input {
  position: absolute;
  left: -999vw;
}

._filter-checkbox-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: _clamp($height-control);
  min-width: _clamp($optionMinWidth); // (85px, 105px)
  padding: 0 _clamp($spacing);
  color: var(--color-foreground);
  font-size: _clamp($font-size-tiny);
  text-transform: uppercase;
  box-shadow: 1px 0 0 var(--color-foreground);
  ._filter-checkbox-input:focus-within + & {
    box-shadow: inset 0 0 0 4px var(--color-outline), 1px 0 0 var(--color-foreground), -1px 0 0 var(--color-foreground);
  }
  ._filter-checkbox-input:active + & {
    color: alpha('foreground', 50%);
  }
  ._filter-checkbox-input:checked + & {
    background-color: var(--color-foreground);
    color: var(--color-background);
  }
}
