@import 'variables-scss';
@import 'functions';

._contextmenu {
  background-color: tint('foreground', 30%);
}

._contextmenu-item {
  @include button-reset();
  @include button();
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  color: var(--color-background);
  background: none;
  border: none;
  font-family: $font-secondary;
  &:disabled {
    color: var(--color-background);
    background: none;
    opacity: .5;
  }
  &:hover {
    background-color: tint('foreground', 30%);
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-foreground);
  }
}