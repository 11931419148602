@import 'variables-scss';
@import 'functions';
@import 'mixins';

._main-header {
  @include breakout-background-color();
  position: sticky;
  z-index: 3;
  top: 0;
  display: flex;
  align-items: center;
  height: _clamp($height-headerMain);
  width: 100%;
  background-color: var(--color-shade-light);
  color: var(--color-foreground);
  &._forceDark {
    background-color: var(--color-background);
  }
}

._main-header--wrapWhenNarrow {
  @extend ._main-header;
  @media screen and (max-width: $width-content-small) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: auto;
    padding: _clamp($width-gap-outer) 0;
  }
}

._main-header-main {
  flex: 1;
  min-width: 0;
  @media screen and (max-width: $width-content-small) {
    ._main-header--wrapWhenNarrow & {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}

._main-header-title {
  @include title();
  font-size: _clamp($font-size-big);
  margin: 0;
  display: flex;
  justify-content: flex-start;
}

._main-header-title-link {
  max-width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
}

._main-header-title-icon {
  flex: 0 0 auto;
  font-size: _clamp($font-size);
  margin-right: _clamp($spacing);
}

._main-header-title-text {
  @include text-ellipsis();
  flex: 1;
  ._main-header-title-link:hover & {
    text-decoration: underline;
  }
}

._main-header-secondary {
  @include list-reset();
  margin-top: _clamp($spacing-xtiny);
  font-family: $font-secondary;
  display: flex;
}

._main-header-secondary--wrapWhenNarrow {
  @extend ._main-header-secondary;
  @media screen and (max-width: $width-content-small) {
    flex-wrap: wrap;
  }
}

._main-header-secondary-item {
  margin: 0;
  &:not(:last-child) {
    margin-right: _clamp($spacing-small);
  }
}

._main-header-secondary-item--dimmed {
  @extend ._main-header-secondary-item;
  opacity: .5;
}

._main-header-back-link {
  @extend ._main-header-secondary-item;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  opacity: .5;
  &:hover {
    opacity: 1;
  }
}

._main-header-back-icon {
  flex: 0 0 auto;
  margin-right: _clamp($spacing-small);
}

._main-header-back-text {
  @include text-ellipsis();
  flex: 1;
  ._main-header-back-link:hover & {
    text-decoration: underline;
  }
}

._main-header-actions {
  flex: 0 0 auto;
  margin-left: _clamp($spacing);
  display: flex;
  @media screen and (max-width: $width-content-small) {
    ._main-header--wrapWhenNarrow & {
      margin-left: 0;
      margin-top: _clamp($spacing);
      flex-direction: row-reverse;
    }
  }
}

._main-header-action {
  &:not(:last-child) {
    margin-right: _clamp($spacing);
  }
  @media screen and (max-width: $width-content-small) {
    ._main-header--wrapWhenNarrow & {
      &:not(:last-child) {
        margin-left: _clamp($spacing);
        margin-right: 0;
      }
    }
  }
}