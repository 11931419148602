/**
 * CSS Variables
 * =============
 * Used for styles that can change on runtime.
 */

 @import 'mixins';

:root,
:before {

  // Include each color in HEX and RGB CSS variables
  @include convertHexColorsIntoCSSVariables($colors);
  
  // Opacities
  --opacity-quarter: .25;
  --opacity-half: .5;
  --opacity-threequarters: .75;

  // Speeds
  --speed-transition-fast: 200ms;
  --speed-transition-normal: 450ms;
  --speed-transition-slow: 750ms;
}


// Create the CSS variables for when the user prefers a dark mode
// @media (prefers-color-scheme: dark) {
//   :root {
//     @include convertHexColorsIntoCSSVariables($colorsDarkMode);
//   }
// }

._forceDark {
  &,
  &:before,
  &:after,
  & *:before,
  & *:after {
    @include convertHexColorsIntoCSSVariables($colorsDarkMode);    
  }
}