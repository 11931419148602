@import 'variables-scss';
@import 'mixins';
@import 'functions';

._vibeList {
  @include list-reset();
  li {
    display: flex;
    &:not(:last-child) {
      margin-bottom: _clamp($spacing);
    }  
  }
}

._vibeList-item-icon {
  flex: 0 0 auto;
  margin-right: _clamp($spacing-small);
  position: relative;
  top: .15em;
}

._vibeList-item-label {
  @at-root a#{&} {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}