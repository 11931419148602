$theme-colors: (
  background: #fff,
  shade-dark: #888,
  shade: #ccc,
  shade-light: #f7f7f7,
  foreground: #1d1d1b,
  foreground-on-danger: #1d1d1b,
  midground: #ddd,
  danger: #da553e,
  warning: #e29521,
  success: #288155,
  accent-1: #903c41,
  accent-2: #374b86,
  accent-3: #288155,
  light-1: #f0eedd,
  light-2: #e0b3ae,
  light-3: #b4beca,
  light-4: #dce9d9,
  light-4-shaded:#bed1ba,
  light-5: #f9c9aa,
  facebook: #1778F2,
  outline: #c7ced7,
  outline-dark: #b4beca80,
  highlight: #cbcba0,
  footer: #cbcba0,
  fluo: #b6ff0a
);
$theme-colorsDarkMode: (
  background: #1d1d1b,
  foreground: #fff,
  shade-light: #2a2a2a
);
$theme-font-primary: 'Basis Grotesque', sans-serif;
$theme-font-secondary: 'Phantom Sans', sans-serif;
$theme-size-border-radius: 0;
$theme-logo-main: url('../assets/logo-main.svg');
$theme-logo-main-menu: url('../assets/logo-main-menu.svg');
$theme-logo-main-width: 531.9;
$theme-logo-main-height: 63.8;
$theme-header-logo-size: (12.121875rem, 18.934375rem);
$theme-footer-logo-size: (3.125rem, 5rem);
$theme-footer-sponsor-width: 100px;
$theme-footer-sponsor-height: 50px;
$theme-title-font-weight: normal;
$theme-title-text-transform: uppercase;
$theme-icon-play: url('../assets/icon-play.svg');
$theme-calls-cta-text-color: var(--color-foreground);
$theme-calls-cta-background-color: var(--color-light-4);
$theme-calls-cta-left: url('../assets/calls-cta-left.svg');
$theme-calls-cta-right: url('../assets/calls-cta-right.svg');